:root {
    --dark-navy: #1a2a33;
    --semi-dark-navy: #1f3641;
    --semi-dark-navy-shadow: #10212a;
    --light-yellow: #f2b137;
    --light-yellow-shadow: #cc8b13;
    --light-yellow-hover: #ffc860;
    --light-blue: #31c3bd;
    --light-blue-shadow: #118c87;
    --light-blue-hover: #65e9e4;
    --silver: #a8bfc9;
    --silver-shadow: #6b8997;
    --silver-hover: #dbe8ed;
}

@font-face {
    font-family: 'Outfit';
    src: url('assets/Outfit-VariableFont_wght.ttf');
}

button {
    cursor: pointer;
}

.App {
    display: flex;
    justify-content: center;

    height: 100vh;
    width: 100vw;

    background-color: var(--dark-navy);

    color: var(--silver);

    font-family: 'Outfit';
}

.flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-row-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.token-logo-group {
    gap: 8px;
}

.token-image-logo {
    width: 32px;
    height: 32px;
}

.site-logo {
    cursor: pointer;
}

.token-image-filter-silver img {
    filter: brightness(0) saturate(100%) invert(90%) sepia(10%) saturate(485%)
        hue-rotate(156deg) brightness(84%) contrast(85%);
}

.token-image-filter-dark-navy img {
    filter: brightness(0) saturate(100%) invert(18%) sepia(23%) saturate(823%)
        hue-rotate(154deg) brightness(96%) contrast(95%);
}

.main-horizontal-content {
    margin: 0px 24px;
    width: 100%;
    min-width: 327px;
    max-width: 460px;

    text-align: center;
}

/*  Styles for new-game-menu */

/*  The margin rule will ensure that the new game menu is at least 24 
    horizontal pixels away from the left and right of the screen. Setting the
    width to 100% will allow the element to be gradually responsive up to the
    maximum specified width */
.new-game-menu {
    align-self: center;
}

/*  The overflow rule is to prevent margin collapse */
.new-game-menu-token-select-box {
    overflow: auto;

    margin: 32px 0px;
    height: 205px;
}

.new-game-pick-mark-heading {
    margin: 24px 0px;
}

.new-game-p-text {
    margin-top: 17px;
    margin-bottom: 30px;
}

.new-game-buttons-group {
    gap: 16px;
}

.new-game-button {
    border: none;
    min-height: 56px;
    width: 100%;

    cursor: pointer;

    font-family: 'Outfit';
    color: var(--dark-navy);
}

.new-game-button p {
    position: relative;

    margin-top: 14px;
    margin-bottom: 22px;
}

.new-game-switch-box {
    margin: 0px 24px;
    min-width: 279px;
    max-width: 412px;
    height: 72px;

    font-size: 20px;
}

.new-game-switch-box-inner {
    position: relative;

    display: flex;
    justify-content: space-around;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    height: 72px;
    padding: 9px 9px;

    background-color: var(--dark-navy);
    cursor: pointer;
}

.switch-option {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.switch-option-indicator {
    position: absolute;
    left: 0;
    top: 0;

    margin: 9px 8px;
    width: calc(100% / 2 - 8px);
    height: 100%;
    max-height: 54px;

    background-color: var(--silver);
}

.switch-radio-accessibiltiy {
    list-style: none;
    padding: 0;
    margin: 0;
}

/*  Switch animations */
@keyframes switch-option-indicator-move-right {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(calc(100%));
    }
}

@keyframes switch-option-indicator-move-left {
    from {
        transform: translateX(calc(100%));
    }
    to {
        transform: translateX(0px);
    }
}

.switch-option-indicator-move-right {
    animation-name: switch-option-indicator-move-right;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.switch-option-indicator-move-left {
    animation-name: switch-option-indicator-move-left;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.overlay-light-blue {
    color: var(--light-blue);
}

.overlay-light-yellow {
    color: var(--light-yellow);
}

.overlay-shadow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: clip;
    mix-blend-mode: normal;
    opacity: 0.5;
    background: #000;
    top: 0;
}

.overlay-box {
    position: absolute;
    width: 100vw;
    height: 228px;

    top: calc((100vh - min(34vh, 266px)) / 2);

    background-color: var(--semi-dark-navy);
}

.overlay-main-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.overlay-box-main-text-only .overlay-main-text-container {
    margin-top: 61px;
    margin-bottom: 24px;
}

.overlay-box-main-and-sub-texts .overlay-main-text-container {
    margin-top: 16px;
    margin-bottom: 24px;
}
.overlay-box-main-and-sub-texts h2 {
    margin-top: 40px;
}

.overlay-main-text {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.5px;

    margin: 0px;
}

.overlay-main-image {
    width: 30px;
    height: 30px;
}

.overlay-sub-text {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.875px;

    margin-top: 40px;
    margin-bottom: 0px;
}

.overlay-button-container {
    gap: 16px;
}

.overlay-button {
    border: none;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--dark-navy);
    max-height: 54px;

    padding: 0px 17px;
}

/*  Game classes */
.play-game {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 24px;
    height: 100%;
    min-height: 516px;
    max-height: 623px;
}

.play-game-top-menu {
    margin-bottom: 64px;
    min-height: 40px;
    max-height: 52px;
}

.play-game-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    gap: 20px;

    width: 60vw;
    min-width: 328px;
    max-width: 460px;
}

.play-game-top-menu-expanding-element {
    position: relative;
    height: 7vw;
    max-height: 52px;
    min-height: 40px;

    border-radius: 5px;
}

.play-game-reload {
    justify-self: end;

    border: none;
    border-radius: 5px;
    aspect-ratio: 1;
    cursor: pointer;
}

.play-game-reload p {
    margin-top: -8px;
    margin-bottom: 0px;

    font-size: 30px;
}

.gameboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    gap: 20px;

    width: 100%;
    height: 100%;
    min-height: 328px;
    max-height: 461px;
}

.gameboard-space {
    border: none;
    min-width: 96px;
    min-height: 96px;
    aspect-ratio: 1/1;
}

.gameboard-token {
    width: 40px;
    height: 40px;
}

.player-turn-indicator-box {
    position: relative;
    border-radius: 5px;
    height: 7vw;
    max-height: 52px;
    min-height: 40px;
}

.player-turn-indicator {
    min-width: 96px;
    max-width: 140px;
    min-height: 40px;
    max-height: 52px;
}

.player-turn-mark {
    position: absolute;
    top: 25%;
    left: calc(15px + (calc(100% - 96px) * 0.33));

    width: 16.7%;
    min-width: 16px;
    max-width: 20px;
    min-height: 16px;
    max-height: 20px;
    aspect-ratio: 1;
}

.player-turn-heading {
    position: absolute;

    top: calc(50% - 18px / 2 - 2px);
    bottom: 13px;
    right: calc(15px + (calc(100% - 96px) * 0.33));

    margin: 0;

    font-size: 14px;
    line-height: 18px;
    font-family: 'Outfit';
    font-weight: 700;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.88px;
}

.play-game-score-cards {
    margin-top: 20px;
}

.score-card {
    border-radius: 10px;

    height: 10vw;
    min-height: 64px;
    max-height: 72px;
    min-width: 96px;
    max-width: 140px;

    font-family: 'Outfit';
    color: var(--dark-navy);
}

.score-card-heading {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15.12px;
    letter-spacing: 0.75px;
}

.score-card-value {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 25.2px;
    letter-spacing: 1.25px;
}

/*  Headings */
.upper-text {
    text-transform: uppercase;
}

.heading-lg {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 5px;
}

.heading-md {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.heading-sm {
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    letter-spacing: 1.25px;
}

.heading-xs {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
}

.body {
    font-size: 14px;
    line-height: 18px;
    font-style: 'normal';
    font-weight: 500;
    letter-spacing: 0.875px;
}

/*  Utility */
.bg-semi-dark-navy {
    background-color: var(--semi-dark-navy);
}

.bg-light-yellow {
    background-color: var(--light-yellow);
}

.bg-light-blue {
    background-color: var(--light-blue);
}

.bg-silver {
    background-color: var(--silver);
}

@media (hover: hover) and (pointer: fine) {
    button.bg-light-yellow:hover {
        background-color: var(--light-yellow-hover);
    }

    button.bg-light-blue:hover {
        background-color: var(--light-blue-hover);
    }

    button.bg-silver:hover {
        background-color: var(--silver-hover);
    }
}

.box-shadow-semi-dark-navy {
    box-shadow: inset 0px -8px 0px #10212a;
}

.box-shadow-semi-dark-navy-sm {
    box-shadow: inset 0px -4px 0px #10212a;
}

.box-shadow-light-yellow {
    box-shadow: inset 0px -8px 0px var(--light-yellow-shadow);
}

.box-shadow-light-yellow-sm {
    box-shadow: inset 0px -4px 0px var(--light-yellow-shadow);
}

.box-shadow-light-blue {
    box-shadow: inset 0px -8px 0px var(--light-blue-shadow);
}

.box-shadow-silver-sm {
    box-shadow: inset 0px -4px 0px var(--silver-shadow);
}

.border-radius-5 {
    border-radius: 5px;
}

.border-radius-10 {
    border-radius: 10px;
}

.border-radius-15 {
    border-radius: 15px;
}

.opacity-50 {
    opacity: 0.5;
}

.w-100 {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .player-turn-heading {
        top: calc(50% - 20px / 2 - 3px);

        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
    }

    .player-turn-indicator-box {
        border-radius: 10px;
    }

    .play-game {
        align-self: center;

        margin-top: 0px;
    }

    .play-game-top-menu {
        margin-bottom: 19px;
    }

    .play-game-top-menu-expanding-element {
        border-radius: 10px;
    }

    .score-card {
        border-radius: 15px;
    }

    .score-card-heading {
        font-size: 14px;
        line-height: 17.64px;
        letter-spacing: 0.88px;
    }

    .score-card-value {
        font-size: 24px;
        line-height: 30.24px;
        letter-spacing: 1.5px;
    }

    .overlay-box {
        height: 266px;
    }

    .overlay-main-text {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 2.5px;
    }

    .overlay-box-main-text-only .overlay-main-text-container {
        margin-top: 67px;
        margin-bottom: 31px;
        gap: 24px;
    }

    .overlay-box-main-and-sub-texts .overlay-main-text-container {
        gap: 24px;
    }

    .overlay-main-image {
        width: 64px;
        height: 64px;
    }

    .overlay-sub-text {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 1px;
    }

    .overlay-box-main-and-sub-texts h2 {
        margin-top: 45px;
    }

    .gameboard-token {
        width: 64px;
        height: 64px;
    }
}
